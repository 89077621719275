<template>
  <div class="appointmentList-view">
    <div  style="float:right;margin-bottom:20px">
      <el-select v-model="value" placeholder="请选择" style="width: 240px">
      <el-option value="" label="全部" class="cbcc">全部</el-option>
      <el-option
        v-for="item in optionList"
        :key="item.productsTypeId"
        :label="item.productsTypeName"
        :value="item.productsTypeId"
      >
      </el-option>
      </el-select>
      <el-date-picker
        v-model="queryDate"
        style="margin-left: 8px"
        align="right"
        type="date"
        :clearable="false"
        @change="dateChange"
        placeholder="选择日期"
      >
      </el-date-picker>
      <el-button
        class="v-primary ml10"
        type="primary"
        @click="queryAppointmentPage"
        >查询</el-button
      >
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="productsId" width="100" align="center" label="产品ID" />
        <el-table-column
          prop="productsName"
          align="center"
          label="产品名称"
          show-overflow-tooltip
        />
        <el-table-column prop="productsTypeName" show-overflow-tooltip align="center" label="分类" />
        <el-table-column prop="price" show-overflow-tooltip align="center" label="价格">
          <template slot-scope="scope"
            ><span v-if="scope.row.price[1]"
              >{{ scope.row.price[0] + "~" + scope.row.price[1] }}元</span
            >
            <span v-else>{{ scope.row.price }}元</span>
          </template>
          <p></p>
        </el-table-column>
        <el-table-column prop="name" align="center" show-overflow-tooltip label="用户姓名" />
        <el-table-column prop="phone" width="120" align="center" label="预约电话">
          <template slot-scope="scope"
            ><span style="color: #fe8575">{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="下单时间">
          <template slot-scope="scope"
            ><span>{{ setDataTimeSec(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="appointmentTime"
          align="center"
          show-overflow-tooltip
          label="预约时间"
        />
        <el-table-column prop="remark" show-overflow-tooltip align="center" label="备注" />
        <el-table-column prop="state" align="center" label="状态">
          <template slot-scope="scope"
            ><span style="color: #fe8575">{{
              scope.row.state | setState()
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope"
            ><span
              style="color: #ff5c6c; cursor: pointer"
              @click="cancel(scope.row)"
              v-if="[1, 2].includes(scope.row.state)"
            >
              取消预约
            </span>
            <span
              style="color: #51cbcd; cursor: pointer"
              @click="affirm(scope.row)"
              v-if="scope.row.state == 1"
            >
              确认预约
            </span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  fetchAppointmentPage,
  fetchProductsTypeList,
  alterAppointmentState,
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";

export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  props:{
    jurisdiction:{
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      optionList: "", //全部分类
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  filters: {
    setState(state) {
      let obj = {
        1: "待确认",
        2: "已确认",
        3: "已完成",
        4: "已取消",
        5: "已过期",
      };
      return obj[state];
    },
  },
  mounted() {
    this.getTableData();
    this.getTypeList();
  },
  methods: {
    //请求预约数据
    getTableData() {
      this.loading = true;
      let data = {
        ignore: true,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productsTypeId: this.value,
        appointmentTime:this.queryDate || '',
        userId:localStorage.getItem("userId"),
        companyId:localStorage.getItem("companyId"),
      };
      fetchAppointmentPage(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.total = res.data.pageInfo.total;
          this.tableData.forEach((v) => {
            v.price = JSON.parse(v.price);
            v.appointmentTime = `${getDataTimeSec(
              v.startTime
            )} - ${getDataTimeSec(v.endTime)}`;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //查询分类
    getTypeList() {
      fetchProductsTypeList({ ignore: true })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      this.getTableData();
    },
    //筛选时间
    dateChange(val) {
      this.queryDate = getDataTimeSec(val, "yy-mm-dd");
    },
    //取消
    cancel(val) {
      if(this.userInfo.roleId || this.jurisdiction){
        this.$prompt("请输入取消理由", "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(({ value }) => {
          let data = {
            appointmentId: val.appointmentId,
            state: 4,
            applyRemark: value,
          };
          this.changeOrderState(data);
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }

    },
    //确认
    affirm(val) {
      if(this.userInfo.roleId || this.jurisdiction){
        this.$confirm("确定接受此次预约么?", "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info ",
        })
        .then(() => {
          let data = {
            appointmentId: val.appointmentId,
            state: 2,
          };
          this.changeOrderState(data);
        })
        .catch(() => {});
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }

    },
    // 修改订单状态
    changeOrderState(params) {
      alterAppointmentState(params).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功！",
        });

        this.getTableData();
      });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
